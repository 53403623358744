/**
 * Michael Panik, 12/8/20
 *
 * Gonna make some notes in this file as it's a little messy with a lot of functionality.
 */
import React, { useState, useEffect } from 'react'
import tw from 'twin.macro'
import Cookies from 'js-cookie'
import PropTypes from 'prop-types'
import useMessageTimeout from '../hooks/useMessageTimeout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faMapMarkerAlt,
  faMobileAlt,
  faHome,
  faBuilding,
} from '@fortawesome/free-solid-svg-icons'
import { A, ALink } from './A'
import { Input, Label } from './Form/FormControls'
import { ButtonLink, Button } from './Button'
import { formatPhoneNumber, geoPromise } from '../helpers'
import { getZipFromCoords, queryBranchByZipCode } from '../api'
import { useHeaderContext } from '../context/Header'
import { RadioField } from '../components/Form/FormControls'

const TopArrow = props => (
  <div
    id="top-arrow"
    css={[
      'bottom: 100%; left: 50%;',
      tw`w-16 absolute transform -translate-x-1/2 hidden lg:(block)`,
    ]}
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241 103">
      <path d="M120.5 0L241 103H0z" fill="#fff" />
      <path d="M120.5 36l54.5 67H66z" fill="#194798" />
    </svg>
  </div>
)

/**
 * This is what actually goes in the location finder popover in the header.
 */
const LocationInfo = ({
  error,
  setError,
  open,
  branch,
  zipCode,
  setZipCode,
  loading,
  setLoading,
  mobile,
  branchType,
  setBranchType,
}) => {
  // This manualZipCode is just simple state to hold whatever is typed in.
  const [manualZipCode, setManualZipCode] = useState(zipCode)

  // I should probably make the button in the form a proper submit input, but didn't
  // feel like digging into the Button component and extending an input[type="submit"]
  // for it. So one function called by the form submit and button click, sorry.
  const handleZipForm = e => {
    e.preventDefault()
    setZipCode(manualZipCode)
  }

  // Lots of stuff in here is confusting because of how I'm having to get data based on ZIP codes.
  // Any time you see branch.title, that's the name of the service area. When you see
  // branch.serviceAreaMeta.serviceAreaBranch[0].title, that's the name of the branch. Does that suck
  // and is it massively confusing? Yes. Am I sorry? Again, yes. I'm deeply sorry.

  const { isCommercial } = useHeaderContext()

  const showError = useMessageTimeout([error, setError], 4000)
  return (
    <div
      css={[
        !mobile && `left: 50%; width:30rem;`,
        tw`transition ease-in-out duration-150 transform lg:(-translate-x-1/2 absolute z-50 bg-white p-4 rounded-lg shadow-2xl mt-4)`,
        tw`hidden lg:(block pointer-events-none opacity-0 )`, // Closed state
        open && tw`block lg:(opacity-100 pointer-events-auto -translate-y-6)`, // open state
      ]}
      aria-expanded={open}
    >
      <TopArrow />
      <div tw="md:(text-center)" id="location-pane">
        <div tw="text-3xl font-bold hidden md:(block)">
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            tw="leading-4 text-yellow-600 mr-3"
          />
          {/* add fallback text if the branch has not been set */}
          {branch !== null ? branch.title : 'Find A Location'}
        </div>
        <form
          css={[
            tw`pt-4 items-center md:(flex justify-center) flex-wrap`,
            branch !== null && tw`border-b mb-4 py-0 md:(pb-6  mb-6)`,
          ]}
          onSubmit={handleZipForm}
        >
          <div tw="flex mb-4 justify-center items-center md:(mb-0 justify-center)">
            <Label
              css={[
                tw`mb-0 mr-2 text-base font-normal text-gray-800 md:(text-current)`,
                isCommercial && tw`text-blue-800`,
              ]}
              htmlFor="zipCode"
              aria-label="zipCode"
            >
              Zip Code:
            </Label>
            <Input
              tw="h-8 w-16 sm:(w-32) mb-0 md:(mr-2) lg:(w-20) p-2 rounded"
              type="text"
              id={mobile ? 'mobile-zipCode' : 'zipCode'}
              value={manualZipCode}
              maxLength="5"
              onChange={e => {
                // ensure that the text entered contains only digits.
                if (!/\D/.test(e.target.value)) {
                  setManualZipCode(e.target.value)
                }
              }}
              tabIndex={open ? 0 : '-1'}
            />
            {mobile && (
              <div tw="flex flex-row-reverse items-center md:(hidden)">
                <ALink
                  to="/branches/"
                  css={[
                    tw`text-blue-600 underline text-base ml-3 font-normal md:(text-current m-0)`,
                    isCommercial && tw`text-blue-800`,
                  ]}
                  tabIndex={open ? 0 : '-1'}
                >
                  View All
                </ALink>
                <Button
                  css={[
                    tw`h-8 pl-3 pr-3 pt-0 pb-0 ml-1 text-center md:(mr-4 h-initial)`,
                    isCommercial &&
                      tw`bg-blue-600 text-white active:(bg-yellow-500 text-gray-800) hover:(bg-blue-500)`,
                  ]}
                  onClick={handleZipForm}
                  tabIndex={open ? 0 : '-1'}
                >
                  Change
                </Button>
              </div>
            )}
          </div>
          {!mobile && (
            <div tw="hidden md:(flex flex-row items-center)">
              <Button
                css={[
                  tw`h-10 py-0 text-center md:(mr-4 h-initial)`,
                  isCommercial &&
                    tw`bg-blue-600 text-white active:(bg-yellow-500 text-gray-800) hover:(bg-blue-500)`,
                ]}
                onClick={handleZipForm}
                tabIndex={open ? 0 : '-1'}
              >
                Change
              </Button>
              <ALink
                to="/branches/"
                css={[
                  tw`text-white mr-4 md:(text-current m-0)`,
                  isCommercial && tw`text-blue-800`,
                ]}
                tabIndex={open ? 0 : '-1'}
              >
                View All
              </ALink>
            </div>
          )}

          <div tw="flex justify-center mt-2">
            <RadioField
              tw=""
              column={mobile}
              data={{
                choices: [
                  {
                    text: 'residential',
                    id: `${mobile ? 'mobile-' : ''}residential`,
                    checked:
                      branchType === 'residential' || branchType === 'null',
                    label: (
                      <span
                        css={[
                          !isCommercial &&
                            tw`text-gray-800 text-base md:(text-current)`,
                        ]}
                      >
                        <FontAwesomeIcon tw="text-yellow-600" icon={faHome} />{' '}
                        Residential
                      </span>
                    ),
                  },
                  {
                    text: 'commercial',
                    id: `${mobile ? 'mobile-' : ''}commercial`,
                    checked: branchType === 'commercial',
                    label: (
                      <span
                        css={[
                          !isCommercial &&
                            tw`text-gray-800 text-base md:(text-current)`,
                        ]}
                      >
                        <FontAwesomeIcon
                          tw="text-yellow-600 ml-1"
                          icon={faBuilding}
                        />{' '}
                        Commercial
                      </span>
                    ),
                  },
                ],
                id: 'res_com',
              }}
              callback={value => {
                setBranchType(value)
              }}
            />
          </div>
        </form>
        <div
          css={[
            tw`bg-red-100 border-red-400 border rounded text-red-600 transition ease-in-out duration-300 `,
            tw`h-0 p-0 opacity-0 my-0`, // hide by default
            showError && tw`h-initial opacity-100 px-5 py-3 my-5 md:(my-0)`,
          ]}
        >
          <p>{error}</p>
        </div>

        {Array.isArray(branch?.serviceAreaMeta?.serviceAreaBranch) &&
          branch?.serviceAreaMeta?.serviceAreaBranch.length > 0 && (
            <div tw="grid grid-cols-2 md:(grid grid-cols-2)">
              <div
                css={[
                  tw`text-left ml-8 mb-4 text-gray-800 md:(text-current)`,
                  isCommercial && tw`text-current`,
                ]}
              >
                <>
                  <p tw="mb-4">
                    {/* Add a fallback link if the branch is undefined */}
                    <strong>Local Branch:</strong>
                    <br />
                    <ALink
                      css={[
                        tw`underline md:(text-current)`,
                        isCommercial && tw`text-blue-600`,
                      ]}
                      to={`/branches/${branch?.serviceAreaMeta?.serviceAreaBranch[0]?.slug}/`}
                      tabIndex={open ? 0 : '-1'}
                    >
                      {branch?.serviceAreaMeta?.serviceAreaBranch[0]?.title}
                    </ALink>
                  </p>
                  {/* display store hours */}
                  {branch !== undefined && (
                    <p tw="mb-4">
                      <strong>Office Hours:</strong>
                      <br />
                      {
                        branch?.serviceAreaMeta?.serviceAreaBranch[0]
                          ?.branchMeta?.branchOfficeHours
                      }
                    </p>
                  )}
                  <A
                    href={`tel:${
                      branch?.serviceAreaMeta?.serviceAreaBranch[0]?.branchMeta
                        ?.branchOfficePhone || '8002399898'
                    }`}
                    css={[
                      tw`block xl:(text-sm) text-blue-600 underline md:(text-current)`,
                      isCommercial && tw`text-blue-600`,
                    ]}
                    tabIndex={open ? 0 : '-1'}
                  >
                    <FontAwesomeIcon icon={faMobileAlt} tw="mr-2 text-lg" />
                    {/* add a fallback number if the branch is undefined */}
                    {formatPhoneNumber(
                      branch?.serviceAreaMeta?.serviceAreaBranch[0]?.branchMeta
                        ?.branchOfficePhone
                    ) || '(800) 239-9898'}
                  </A>
                </>
              </div>
              <div tw="my-8 md:(m-0)">
                <ButtonLink
                  large
                  tw="pl-4 pr-4"
                  to="/residential/quote/"
                  css={[
                    isCommercial &&
                      tw`bg-blue-600 text-white 
                            active:(bg-yellow-500 text-gray-800) 
                            hover:(bg-blue-500)`,
                  ]}
                  tabIndex={open ? 0 : '-1'}
                >
                  Get a Quote{' '}
                  <FontAwesomeIcon icon={faChevronRight} tw="ml-2" />
                </ButtonLink>
              </div>
            </div>
          )}
      </div>
    </div>
  )
}

LocationInfo.propTypes = {
  mobile: PropTypes.bool,
}

// This is the section of the header that holds all location finder info.
export const LocationFinder = props => {
  const { isCommercial } = useHeaderContext()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [latLng, setLatLng] = useState(null)
  const [zipCode, setZipCode] = useState(null)
  const [branchType, setBranchType] = useState('')
  const [branch, setBranch] = useState(null)
  const [error, setError] = useState(null)

  const { mobile } = props

  const toggleOpen = () => setOpen(!open)

  const closeLocationPane = React.useCallback(
    e => {
      if (!e.target.closest('#location-pane') && open) {
        setOpen(false)
      }
    },
    [open]
  )

  /* event listener to close the location pane if the user clicks ouside it */
  useEffect(() => {
    document.addEventListener('click', closeLocationPane)
    return () => document.removeEventListener('click', closeLocationPane)
  }, [open])

  // When the component is mounted, look for cached ZIP and branch data. If not
  // found, use navigator.geolocation to get your current position, and update
  // state
  useEffect(() => {
    const cachedBranch = Cookies.get('localResidentialBranchData') || null
    const cachedZip = Cookies.get('zipCode') || null
    const branchFilter = Cookies.get('branchFilter') || 'residential'

    if (cachedBranch && cachedZip) {
      setBranch(JSON.parse(cachedBranch))
      setZipCode(cachedZip)
      setBranchType(branchFilter)
      setLoading(false)
      return
    }

    if (
      'userAgent' in navigator &&
      navigator.userAgent.indexOf('Firefox') > -1
    ) {
      // prevent geolocation in firefox
      setLoading(false)
      return
    }

    // get the users location in a promise
    geoPromise()
      .then(position => {
        // set the latitude and longitude
        const { latitude, longitude } = position.coords

        setLatLng([latitude, longitude])
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        if (error.code === error.PERMISSION_DENIED) {
          // user blocked location permissions
          setError(
            'Unable to determine your ZIP code automatically. Please enter it below.'
          )

          // automatically prompt the user for a location
          // setOpen(true);
        } else {
          setError(error.message)
        }
      })
      .finally(() => setLoading(false))
  }, [])

  // When latLng is set/updated, geocode with Google Maps API. Get back a bunch of stuff,
  // loop over it until you find a ZIP code, then set the Zip code state.
  useEffect(() => {
    if (!latLng) {
      return
    }
    setLoading(true)

    const [lat, lng] = latLng

    getZipFromCoords({ lat, lng })
      .then(res => res.json())
      .then(json => {
        const filteredAddressComponents = json.results[0].address_components.filter(
          ({ types }) => types.includes('postal_code')
        )
        if (filteredAddressComponents.length > 0) {
          const [{ long_name }] = filteredAddressComponents
          setZipCode(long_name)
        }
        setLoading(false)
      })
      .catch(error => setLoading(false))
      .finally(() => setLoading(false))
  }, [latLng])

  // When ZIP code state is updated, query WordPress to find service ares that
  // contain the ZIP code we pass. When one is found, set the branch state.
  useEffect(() => {
    if (!zipCode) {
      return
    }

    if (branchType === '') {
      setBranchType('residential')
      return
    }

    if (!branch) {
      setLoading(true)
    }

    console.log({ branchType })
    queryBranchByZipCode(zipCode)
      .then(({ data }) => {
        const branches = data?.serviceAreas?.edges.map(({ node }) => {
          const serviceAreaBranch = node?.serviceAreaMeta?.serviceAreaBranch?.filter(
            ({ branchTypes: { nodes } }) => {
              const types = nodes.map(({ name }) => name.toLowerCase()).join('')
              return types.includes(branchType.toLowerCase() ?? '')
            }
          )

          const filteredNode = {
            ...node,
            serviceAreaMeta: {
              ...node.serviceAreaMeta,
              serviceAreaBranch,
            },
          }

          return filteredNode
        })

        if (branches.length) {
          const [branch] = branches
          setBranch(branch)
          Cookies.set('localResidentialBranchData', branch)
          Cookies.set('zipCode', zipCode)
          Cookies.set('branchFilter', branchType)
          setError(null)
        } else {
          const errorMessage =
            'Unable to locate a service area for your ZIP code.'
          setError(errorMessage)
          setBranch(null)
        }
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [zipCode, branchType])

  return (
    <div tw="relative border-t bg-white sm:(border-t-0 bg-white mt-0 ml-0 mr-0)">
      <div tw="flex items-center" {...props}>
        <div tw="w-full">
          <div tw="flex content-center justify-center -ml-4">
            <Button link onClick={toggleOpen} tw="cursor-pointer">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                tw="leading-3 text-yellow-600 text-3xl md:(text-2xl mr-3) xl:(text-5xl mr-4)"
              />
            </Button>
            <div tw="mt-3 -ml-4 sm:(mt-0 -ml-1)">
              {/* branch title */}
              {loading ? (
                <div
                  css={[
                    'height:15px;width:100px;margin-bottom:2px;',
                    tw`bg-blue-200 rounded-full animate-pulse`,
                  ]}
                />
              ) : (
                <a
                  onClick={toggleOpen}
                  css={[
                    tw`cursor-pointer block font-bold text-gray-800 md:(text-blue-600 text-lg) text-3xl leading-none`,
                    isCommercial && tw`text-current`,
                  ]}
                >
                  {/* add fallback text if a branch isn't defined */}
                  {branch?.title || 'Find a Location'}
                </a>
              )}

              {/* change location */}
              <div
                css={[tw` text-xs xl:(text-sm) underline leading-none mb-1`]}
              >
                <ALink
                  css={[
                    tw`hidden sm:(block) text-blue-600 md:(text-blue-400)`,
                    isCommercial && tw`text-blue-600`,
                  ]}
                  to="/branches"
                  onClick={e => {
                    e.preventDefault()
                    toggleOpen()
                  }}
                >
                  {loading ? (
                    <div
                      css={[
                        'height:14px;width:120px;',
                        tw`bg-blue-200 rounded-full animate-pulse`,
                      ]}
                    />
                  ) : (
                    <>Change Location</>
                  )}
                </ALink>
              </div>

              {/* phone number */}
              {loading ? (
                <div>
                  <FontAwesomeIcon
                    icon={faMobileAlt}
                    tw="mr-2 text-gray-200 animate-pulse"
                  />
                  <div
                    css={[
                      'height:14px;width:80px;',
                      tw`inline-block bg-gray-300 rounded-full animate-pulse`,
                    ]}
                  />
                </div>
              ) : (
                <A
                  href={`tel:${
                    branch != null
                      ? branch.serviceAreaMeta?.serviceAreaBranch[0]?.branchMeta
                          ?.branchOfficePhone
                      : '800-239-9898'
                  }`}
                  css={[
                    tw`hidden sm:(block) text-blue-600 underline md:(text-blue-600) text-xs xl:(text-sm)`,
                    isCommercial && tw`text-blue-600`,
                  ]}
                >
                  <FontAwesomeIcon icon={faMobileAlt} css={[tw`mr-2`]} />
                  {/* Add a fallback number if a branch isn't set */}
                  {branch !== null
                    ? formatPhoneNumber(
                        branch.serviceAreaMeta?.serviceAreaBranch[0]?.branchMeta
                          ?.branchOfficePhone
                      )
                    : '(800) 239-9898'}
                </A>
              )}
            </div>
          </div>
          <LocationInfo
            open={mobile ? true : open}
            error={error}
            setError={setError}
            branch={branch}
            setZipCode={setZipCode}
            loading={loading}
            setLoading={setLoading}
            zipCode={zipCode}
            mobile={mobile}
            branchType={branchType}
            setBranchType={setBranchType}
          />
        </div>
      </div>
    </div>
  )
}
